<template>
  <aside class='lg:py-6 lg:px-6 lg:py-0 lg:px-0 col-span-12 lg:col-span-3 sidebar'>
    <nav class='space-y-1 sticky top-12 nav'>
      <nav as="nav" class="lg:hidden">
        <div>
          <div class="bg-white border-b flex items-center gap-2">
            <button
              class="p-2 bg-white border-r text-gray-500"
              @click="toggleNavBar">
              <div class="h-8 w-8 flex justify-center items-center">
                <div v-if="!navBarOpen">
                  <MenuAlt2Icon class="h-6 w-6" />
                </div>
                <div v-else>
                  <XIcon class="h-5 w-5" />
                </div>
              </div>
            </button>
            <div class="px-2 py-1 overflow-hidden">
              <div v-if="$route.fullPath.includes('/listings/')" class="text-sm text-gray-500 flex items-center gap-1">
                <span>Listing</span>
                <ChevronRightIcon class="h-4 w-4" />
                <p class="truncate text-gray-800 font-medium">{{title}}</p>
              </div>
              <div v-if="$route.fullPath.includes('/account')">
                <span class="text-sm text-gray-500">
                  Account
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="navBarOpen">
          <div>
            <!-- Mobile navbar -->
            <div class="p-3 bg-white mb-4 shadow-xl border-b">
              <div
                v-for='item in subNavItems'
                :key='item.name'>
                <router-link
                  v-if="item.name === 'Pricing & availability'"
                  :to="{ name: item.routeName, params: { subItemId: $route.meta.props.unitsList[0].id }}"
                  :class="[
                    item.current
                      ? 'bg-gray-50 text-highlight-600 hover:bg-white'
                      : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                    'group parent-item rounded-md px-3 py-2 flex items-center text-sm font-normal'
                  ]"
                  :aria-current="item.current ? 'page' : undefined">
                  <span class='truncate'>
                    {{ item.name }}
                  </span>
                </router-link>

                <router-link
                  v-else
                  :to='{ name: item.routeName }'
                  :class="[
                    item.current
                      ? 'bg-gray-50 text-highlight-600 hover:bg-white'
                      : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                    'group parent-item rounded-md px-3 py-2 flex items-center text-sm font-normal'
                  ]"
                  :aria-current="item.current ? 'page' : undefined">
                  <span class='truncate'>
                    {{ item.name }}
                  </span>
                </router-link>
                <div v-if="item.sections">
                  <ul
                    v-if="item.sections && item.activeOn.includes($route.name)"
                    class="space-y-3 lg:space-y-2 border-l border-gray-100
                    dark:border-gray-800 mt-1 ml-3">
                    <router-link
                      v-for="subItem in item.sections"
                      :key="subItem.id"
                      :to="subItem.id"
                      class="child-item"
                      :class="$route.hash === subItem.id ? 'child-item-active' : ''">
                      {{subItem.name}}
                    </router-link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div
        v-for='item in subNavItems'
        :key='item.name'
        class="hidden lg:block">
        <router-link
          :to='{ name: item.routeName }'
          :class="[
            item.current
              ? 'bg-gray-50 text-highlight-600 hover:bg-white'
              : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
            'group parent-item rounded-md px-3 py-2 flex items-center text-sm font-normal',
            !item.active || !isCreateProductMode ? '' : 'opacity-50 pointer-events-none'
          ]"
          :aria-current="item.current ? 'page' : undefined">
          <span class='truncate'>
            {{ item.name }}
          </span>
        </router-link>
        <div v-if="item.sections">
          <ul
            v-if="item.sections && item.activeOn.includes($route.name)"
            class="space-y-6 lg:space-y-2 border-l border-gray-100
                    dark:border-gray-800 mb-4 mt-1 ml-3">
            <router-link
              v-for="subItem in item.sections"
              :key="subItem.id"
              :to="subItem.id"
              class="child-item"
              :class="[$route.hash === subItem.id ? 'child-item-active' : '',
                       subItem.active || !isCreateProductMode ? '' : 'opacity-50 pointer-events-none']">
              {{subItem.name}}
            </router-link>
          </ul>
        </div>
      </div>
    </nav>
  </aside>
</template>

<script>
import { MenuAlt2Icon, XIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useRoute } from 'vue-router'

export default {
  name: 'TheSideBarNav',
  components: {
    MenuAlt2Icon,
    XIcon,
    ChevronRightIcon
  },
  props: {
    subNavItems: {
      type: Array,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const title = computed(() => store.state.product.product.generalInfo.title);

    const isCreateProductMode = computed(() => store.state.product.mode === 'create' && route.name !== 'profile');

    const navBarOpen = ref(false);
    const toggleNavBar = () => {
      navBarOpen.value = !navBarOpen.value
    }

    watch(
      () => route.fullPath,
      () => {
        navBarOpen.value = false;
      }
    )

    return {
      title,
      navBarOpen,
      toggleNavBar,
      isCreateProductMode
    }
  }
};
</script>

<style scoped>
.sidebar .nav .parent-item.router-link-exact-active {
  @apply text-gray-800 font-medium
}

.nav .child-item-active {
  @apply border-l !border-gray-500 font-medium text-highlight-500
}
.nav .child-item {
  @apply block border-l pl-4 -ml-px border-transparent hover:border-gray-400 text-gray-700 text-sm hover:text-gray-900 cursor-pointer
}
/*
.sidebar .nav .child-item.router-link-exact-active {
  @apply text-highlight-500 font-medium
}
 */
</style>
